@import 'vendor/include-media/dist/include-media';
@import 'vendor/bourbon/app/assets/stylesheets/bourbon';

$breakpoints: (small: 768px, medium: 990px, large: 1140px);

// 

// Import grid settings :
@import 'grid-settings';


// 
// BOOTSTRAP
// 

@import "bootstrap/variables";
@import "bootstrap/mixins";

@import "bootstrap/tooltip";
@import "bootstrap/popovers";

// _type.scss
@include text-emphasis-variant('.text-primary', $brand-primary);

@include text-emphasis-variant('.text-success', $state-success-text);

@include text-emphasis-variant('.text-info', $state-info-text);

@include text-emphasis-variant('.text-warning', $state-warning-text);

@include text-emphasis-variant('.text-danger', $state-danger-text);

// 
// BOOTSTRAP END
// 

// @import 'tooltip';
@import 'side-blocks';

// @include gridle_init();

// Generate classes :
// @include gridle_generate_classes();
@include gridle_generate_classes();

// you can generate classes separately if you need :
// @include gridle_generate_classes(default);   // default is the base state always registered
// @include gridle_generate_classes(mobile);    // only the mobile state
// @include gridle_generate_classes(all, -helpers); // all states, all packages but the helpers

// generate a center custom class for all the states :
// @include gridle_generate_custom_class( ('center','@','%state') ) {
//  text-align:center;
// }
// this will produces classes : center, center@mobile, center@tablet, center@ipad-landscape
// for separators, you can use -, --, _, __ or @

// Max size :
.container {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 15px;
}

[class*="gr-"] {
    @include gridle_set((
        mobile : (
            grid : 12,
            push : 0,
            pull : 0,
            prefix : 0,
            suffix : 0
        )
    ));
}

// 

small {
    font-size: 80%;
}

.header.panel {
    >.header.links {
        >li {
            font-size: 15px;
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;

            >a,
            >a:visited {
                color: #fff;

                &:hover {
                    // color: #AE4348;
                    color: inherit;
                    text-decoration: underline;
                }
            }
        }

        >.authorization-link {
            &:after {
                display: none;
            }
        }

        &.shop {
            float: left;
        }
    }

    @include media("<=medium") {
        padding-top: 0 !important;

        >.header.links {
            float: none;

            >li {
                margin-left: 0;
                margin-right: 15px;
            }

            &.shop {
                float: none;
            }
        }
    }
}

.page-header {
    background: url('../images/header-panel-bg.png') no-repeat center bottom #242e75;

    .panel.wrapper {
        background: #202063;
        border-bottom: 0;

        a {
            color: #fff;
            text-transform: uppercase;
        }
    }

    // .header.content {
    //     @include clearfix();
    // }

    @include media(">small") {
        .header.content {
            padding-top: 10px;
            height: 88px;
            box-sizing: border-box;
        }

        .logo {
            margin: 0;
            margin-top: 6px;
        }
    }

    @include media("<=large") {
        .logo {
            width: 400px;
        }
    }
    
    @include media("<=medium") {
        background-size: 100% 100%;

        .logo {
            margin-top: 10px;
            width: 230px;
            overflow: hidden;

            img {
                width: 400px;
                max-width: none;
            }
        }
    }

    @include media("<=small") {
        margin-bottom: 0;

        .logo {
            width: 160px;
            margin: 9px 0;
            margin-left: 40px;
            overflow: hidden;

            img {
                width: 280px;
                max-width: none;
            }
        }
    }
}

.nav-toggle {
    &:before {
        color: #fff;
    }

    &:hover:before {
        color: inherit;
        // color: #fff;
        // border: 1px solid #fff;
    }
}

.block-search {
    input {
        border: 1px solid #000;
        font-size: 16px;
        height: 40px;
    }

    .action.advanced {
        display: none;
    }

    .action.search {
        &:before {
            font-size: 20px;
            line-height: 30px;
        }

        &.disabled {
        }
    }

    .control {
        padding-bottom: 0;
    }

    @include media(">=large") {
        width: 350px;
        margin-left: 60px;
    }

    @include media(">=medium", "<large") {
        margin-left: 30px;
    }

    @include media(">=small") {
        float: left;
        width: 280px;
        margin-top: 12px;
    }
    @include media("<small") {
        width: 100%;
        // float: right;
        // clear: both;
        // height: 40px;

        .label {
            top: 20px;
            right: 60px;
            position: absolute;
            margin-right: 10px;
            border: 1px solid #fff;

            &:before {
                margin: 0;
                padding: 0 5px;
                color: #fff;
            }

            &:hover {
                &:before {
                    background: #fff;
                    color: #000;
                }
            }
        }

        .label.active+.control {
            // background: #000;
        }

        .control {
            width: 100%;
            border: none;
            clear: both;
            // float: left;

            input {
                // left: -30px;
            }
        }
    }
}

// 

.navigation {
    @include media(">=small") {
        padding-left: 10px;
        padding-right: 10px;
    }

    @include media(">=small", "<=medium") {
        li.home {
            display: none;
        }

        li.level0 {
            >a {
                font-size: 12px;
            }
        }
    }

    @include media(">medium", "<=large") {
        li.level0 {
            >a {
                font-size: 16px;
            }
        }
    }
}

// 

#maincontent.full-width {
    max-width: none;
    padding: 0;
}

.columns {
    .column.main {
        padding-bottom: 0;
    }
    
    @include media("<=small") {
        display: block;
        
        .column.main {
            display: block;
        }
    }
}

.cms-index-index {
    .nav-sections {
        @include media(">small") {
            margin-bottom: 0;
        }
    }

    // #maincontent {
    //     max-width: none;
    //     padding: 0;
    // }

    // .block-products-list {
    //     display: none;
    // }

    @include media(">=1550px") {
        #tp-ad {
            a {
                position: absolute;
                top: 0;
                left: 0;
                background: #fff;
                width: 255px;
                height: 255px;
                transform: rotate(-45deg) translateY(-73%);
                border-bottom: 5px solid #7a75d2;
                z-index: 5000;
                display: block;
                padding: 0 20px;
                padding-top: 180px;
                color: #352f9b;
                text-align: center;
                box-sizing: border-box;
                transition: all 0.3s ease;

                &:hover {
                    text-decoration: none;
                    background: #7a75d2;
                    color: #fff;
                    border-color: rgba(#fff, 0.2);
                }

                strong {
                    display: block;
                    padding: 0 20px;
                    font-size: 18px;
                }

                span {
                    display: block;
                    font-size: 13px;
                    white-space: nowrap;
                }
            }
        }
    }
    @include media("<1550px") {
        #tp-ad {
            display: none;
        }
    }

    .tp-banner {
        display: none;
    }

    .tp-banner-container,
    .tp-banner {
        position: relative;
        width: 100%;

        .tp-bannertimer {
            top: auto;
            bottom: 0;
            height: 5px;
            // background: #000;
            box-shadow: inset 0 0 4px rgba(#7a75d2, 0.35);
        }

        ul {
            padding: 0;
            margin: 0;

            li {
                padding: 0;
                margin: 0;
            }
        }
    }

    .block-products-list {
        margin: auto;
        padding: 10px 15px;
        max-width: 1200px;

        .block-title {
            margin-bottom: 0;
            // margin-top: 10px;
            // font-size: 24px;

            // strong {
            //     display: inline-block;
            //     padding: 0 10px;
            //     border-bottom: 1px solid #aaa;
            // }
        }

        .call-for-price+.product-item-actions .actions-primary {
            display: none;
        }
    }
}

.block-more-info {
    margin-top: 20px;
    margin-bottom: 20px;

    .gr-4 {
        a {
            position: relative;
            display: block;
            overflow: hidden;

            h4 {
                position: relative;
                margin: 0;
                margin-top: -50px;
                line-height: 50px;
                background: rgba(#fff, 0.8);
                text-align: center;
                font-size: 1.4em;
                color: #222;
                font-weight: 400;
            }

            img {
                display: block;
                transition: all 0.5s ease;
            }

            &:hover {
                text-decoration: none;

                img {
                    transform: scale(1.1);
                    transition: all 1.1s ease;
                }
            }
        }
    }
}

.block-more-info h3,
.block-products-list .block-title strong {
    display: inline-block;
    margin: 20px 5px;
    padding: 0 10px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1.7em;
    border-bottom: 2px solid #0072bc;
}

// 

.minicart-wrapper {
    .action {
        &.showcart {
            &:before {
                width: 61px;
                height: 50px;
                margin-right: 12px !important;
                background: url('../images/cart.png') no-repeat left top;
                background-size: contain;
                content: '' !important;
            }

            .text {
                @extend .abs-visually-hidden-reset;
                display: inline-block;
                font-size: 24px;
                font-weight: 700;
                color: #fff;
                text-transform: uppercase;
            }

            .counter.qty {
                display: block;
                position: absolute;
                left: 75px;
                top: 25px;
                background-color: transparent;
                text-transform: uppercase;
                font-weight: 600;
                color: #7273b0;

                .counter-label {
                    @extend .abs-visually-hidden-reset;
                }
            }
        }
    }

    @include media("<=small") {
        margin-top: 10px;

        .action {
            &.showcart {
                &:before {
                    width: 40px;
                    height: 30px;
                    margin-right: 2px !important;
                }

                .text {
                    display: none;
                    font-size: 16px;
                }
            }
        }
    }
}

// 

.product-info-main .price-box.call-for-price,
.product-item .price-box.call-for-price {
    padding: 6px 10px;
    background: #0072bc;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
}

.product-item {
    .price-box.call-for-price {
        padding: 2px 4px;
    }
}

.product-info-main {
    .price-box {
        &.call-for-price {
            font-size: 20px;
        }
    }
}

// 

#scroll-to-top,
#scroll-to-bottom {
    position: fixed;
    right: 15px;
    width: 56px;
    height: 56px;
    transition: background-color 0.3s ease;
    z-index: 5001;

    &:hover {
        // background-position: 0 -56px !important;
        background-color: rgba(#0072bc, 0.2) !important;
    }

    @include media("<=small") {
        display: none !important;
    }
}

#scroll-to-top {
    bottom: 80px;
    background: url('../images/arrow_totop.png') no-repeat 0 0 #0072bc;

    &:hover {
        background-position: 0 -56px;
    }
}

#scroll-to-bottom {
    bottom: 15px;
    background: url('../images/arrow_tobottom.png') no-repeat 0 -56px #0072bc;

    &:hover {
        background-position: 0 0;
    }
}

// 

.new-caption {
    h3, h4 {
        font-weight: 400;
    }
    h3 {
        font-size: 3.2em;
        line-height: 1.2em;
        letter-spacing: 0;
        big {
            font-size: 1.5em;
            line-height: 1.2em;
        }
    }
    h4 {
        margin-top: 0.8em;
        font-size: 2em;
        line-height: 1.7em;
    }
    p {
        margin-top: 2.5em;
        font-size: 1.3em;
        line-height: 1.4em;
    }
    a.lnk {
        position: relative;
        display: inline-block;
        margin-top: 2.5em;
        font-size: 1.5em;
        padding-left: 45px;
        margin-left: 10px;
        text-decoration: underline;
        transition: all 0.3s ease;
        -webkit-backface-visibility: hidden;

        // &:before {
        //     content: '';
        //     position: absolute;
        //     display: block;
        //     left: 0;
        //     top: 0;
        //     width: 35px;
        //     height: 35px;
        //     background: #ee2020;
        //     border-radius: 50%;
        //     margin-top: -7px;
        // }

        // &:after {
        //     content: '';
        //     position: absolute;
        //     display: block;
        //     left: 12px;
        //     top: 2px;
        //     border-style: solid;
        //     border-color: transparent transparent transparent #fff;
        //     border-width: 8px 0 8px 16px;
        //     -webkit-transform: rotate(360deg);
        //     -webkit-backface-visibility: hidden;
        // }

        // &:before {
        //     content: '\7c\a0';
        //     display: inline-block;
        //     background: #ee2020;
        //     border-radius: 50%;
        //     margin-right: 10px;
        //     font-size: 2em;
        //     line-height: 1em;
        //     font-family: monospace;
        //     vertical-align: middle;
        // }

        &:before {
            // font-family: fontawesome;
            display: inline-block;
            content: '\25B6';
            padding: 0.3em 0.6em;
            padding-bottom: 0.5em;
            letter-spacing: -3px;
            font-size: 1em;
            line-height: 1em;
            background: #ee2020;
            color: #fff;
            border-radius: 50%;
            margin-right: 10px;
        }

        &.second-line:before {
            top: 7px;
        }
        &.second-line:after {
            top: 9px;
        }
        &:hover {
            color: #ee2020 !important;
            // @include transform(scale(1.1));
            &:after {
            }
        }
    }
    &.light {
        h3, h4, p, a {
            color: #fff;
        }
    }
    &.dark {
        h3, h4, p, a {
            color: #312eaf;
        }
    }

    &.right {
    }

    &.align-center {
        text-align: center;
    }

    &.align-right {
        text-align: right;
    }
}

// 

@media only screen and (min-width: 768px) {
    .product.media {
        margin-bottom: 0;
    }
}

// 

.footer.content {
    >div {
        h4 {
            margin-bottom: 15px;
        }
    }

    .contact-data {
        float: left;
    }

    .footer-links-section {
        float: left;

        .footer.links {
            display: block;
        }
    }

    .copyright {
        clear: both;
        margin-top: 0;
        padding-top: 20px;
    }
}

// 

.sbs {
    margin: auto;
    // max-width: 1600px;
    font-size: 15px;

    p, ul {
        text-align: justify;
    }

    @include media(">=medium") {
        .sbs-row {
            display: flex;
            align-items: stretch;
            justify-content: center;

            >.image {
                position: relative;
                width: 55%;
                min-height: 650px;
                overflow: hidden;
                display: flex;
                align-items: center;

                &:after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: block;
                    content: '';
                }

                img {
                    position: absolute;
                    left: 50%;
                    right: -50%;
                    top: 50%;
                    bottom: -50%;
                    transform: translateX(-50%) translateY(-50%);

                    // left: 0;
                    // right: 0;
                    // top: 0;
                    // bottom: 0;
                    // margin-left: -50%;
                    // margin-right: -50%;
                    // margin-top: -50%;
                    // margin-bottom: -50%;

                    // max-width: 100%;
                    // max-height: 100%;
                    // display: block;
                    // height: auto;
                    // width: 100%;
                    // height: 100%;
                    // max-height: 50%;
                    // min-width: 100%;
                    max-width: none;
                    // object-fit: cover;
                    // margin-left: 50%;
                    // margin-top: 50%;
                }

                &.mw-none {
                    img {
                        max-width: none;
                    }
                }

                &.mw-100 {
                    img {
                        max-width: 100%;
                    }
                }
            }

            >.content {
                display: flex;
                // align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 45%;
                box-sizing: border-box;
                text-align: left;

                >.text {
                    max-width: 700px;
                }
            }

            &:nth-child(odd) {
                >.image {
                    &:after {
                        background: linear-gradient(to right, rgba(#fff, 0), rgba(#fff, 0.1) 40%, rgba(#fff, 0.96) 90%, rgba(#fff, 1));
                    }
                }
            }

            &:nth-child(even) {
                >.image {
                    &:after {
                        background: linear-gradient(to left, rgba(#f6f6f6, 0), rgba(#f6f6f6, 0.1) 40%, rgba(#f6f6f6, 0.96) 90%, rgba(#f6f6f6, 1));
                    }
                }
            }

            &:nth-child(even) {
                background: #f6f6f6;

                >.image {
                    order: 2;
                }

                >.content {
                    order: 1;
                    align-items: flex-end;
                }
            }
        }
    }

    // @include media(">=1600px") {
    //     .sbs-row {
    //         >.image {
    //             width: 55%;
    //         }

    //         >.content {
    //             width: 45%;
    //         }
    //     }
    // }

    @include media(">=medium", "<large") {
        font-size: 12px;

        .sbs-row {
            >.image {
                width: 60%;
                min-height: 350px;

                img {
                    height: 100%;
                }
            }

            >.content {
                width: 40%;
            }
        }
    }

    @include media(">=large", "<1400px") {
        font-size: 13px;

        .sbs-row {
            >.image {
                width: 60%;
                min-height: 370px;

                img {
                    height: 100%;
                }
            }

            >.content {
                width: 40%;
            }
        }
    }

    @include media(">=1400px", "<1600px") {
        font-size: 14px;

        .sbs-row {
            >.image {
                width: 60%;
                min-height: 500px;

                img {
                    height: 100%;
                }
            }

            >.content {
                width: 40%;
            }
        }
    }

    .sbs-row {
        // margin-bottom: 20px;

        h3 {
            font-size: 2em;
            font-weight: 400;
        }

        h4 {
            margin: 0.6em 0;
            font-size: 1.7em;
            font-weight: 300;
        }

        p {
            font-size: 1.1em;
        }

        >.content {
            padding: 20px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    @include media(">=medium") {
        .sbs-row {
            >.image {
                &.valign-top.halign-left {
                    img {
                        top: 0;
                        left: 0;
                        transform: none;
                    }
                }
            }
        }
    }
}

// 

// .form-shipping-address .field[name="shippingAddress.region"] {
//     display: none;
// }

#shipping-zip-form .field[name="shippingAddress.region"] {
    display: none;
}

// 

.checkout-container {
    margin-top: 20px;
}

.authentication-wrapper {
    margin-top: 0;
}

@include media(">=small") {
    .fieldset > .field {
        margin-bottom: 15px;
    }
}

.abs-checkout-title, .opc-wrapper .step-title, .opc-block-shipping-information .shipping-information-title, .opc-block-summary > .title, .checkout-agreements-items .checkout-agreements-item-title {
    font-family: inherit;
    font-style: inherit;
    line-height: inherit;
}

body._has-modal-custom .modal-custom-overlay {
    display: none;
}

// 

.actions-toolbar {
    >.secondary {
        margin-right: 15px;

        >a.action {
            margin-right: 0;
        }
    }
}

// 

.widget.blog-search .action.search:before {
    font-family: "icons-blank-theme" !important;
}

.opc-wrapper {
    .field._required,
    .field.required {
        >.label:after {
            content: '*';
            color: #e02b27;
            font-family: inherit;
            font-style: inherit;
            font-weight: inherit;
            line-height: inherit;
            font-size: 12px;
            margin: 0 0 0 5px;
        }

        @media only screen and (min-width: 768px) {
            >.label:after {
                top: 9px;
                position: absolute;
                margin-left: 9px;
            }
        }
    }
}

.opc-wrapper .fieldset > .field > .label {
    padding-right: 25px !important;
}

@media only screen and (min-width: 768px) {
    .opc-wrapper .form-login, .opc-wrapper .form-shipping-address {
        max-width: 670px;
    }
}

.checkout-payment-method .field-select-billing, .checkout-payment-method .billing-address-form {
    max-width: 670px;
}

// 

.product-info-main {
    .product.attribute.overview {
        >.type {
            display: none;
        }
    }
}

// 

.product-item .price-box {
    margin: 6px 0;
}

// 

// @media screen and (max-width: 768px) {
//     [class*=gr-] {
//         box-sizing: border-box;
//         max-width: 100%;
//     }
// }

.product.data.items>.item.content {
    @include media("<=small") {
        padding: 5px;
    }
}

.product.attribute.description {
    @include media("<=small") {
        table {
            // display: block;
            width: auto !important;
            max-width: 100% !important;
            
            tbody, tr, td, th {
                // display: block;
                width: auto !important;
                max-width: 100% !important;
            }
        }
    }
}
