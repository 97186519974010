
$icon-color1: #3B5998;
$icon-color2: #242e75;

.side-slide {
    $width: 300px;
    $icon: 42px;
    position: fixed;
    top: 10%;
    right: 0;
    z-index: 5000;
    list-style: none;
    text-align: left;
    margin: 0;
    > li {
        margin-top: 5px;
        margin-bottom: 0;
        float: right;
        clear: both;
        display: block;
        position: relative;
        margin-right: 0;
        @include transition(all 0.6s ease);
        box-shadow: 0 0 15px rgba(#000, 0.2);
        width: $icon;
        // overflow: hidden;
        > .icon {
            display: block;
            background: $icon-color1;
            // border: 2px solid darken($icon-color1, 7%);
            box-shadow: 0 0 15px rgba(#000, 0.4) inset;
            text-align: center;
            width: $icon;
            height: $icon;
            line-height: $icon;
            cursor: pointer;
            color: #fff;
            @include transition(all 0.6s ease);
            > .fa {
                line-height: $icon;
                font-size: #{floor($icon*0.55)} !important;
            }
        }
        > div {
            background: #fff;
            border: 3px solid $icon-color1;
            top: 0;
            left: $icon;
            position: absolute;
            width: #{$width+6px};
            box-sizing: border-box;
            @include transition(all 0.6s ease);
        }

        &:not(.hover) .popover {
            display: none !important;
        }

        &.hover {
            // margin-right: #{$width+6px};
            width: #{$width+$icon+6px}; // 6px bd width
            height: auto;
            z-index: 10;
            >.icon {
                border-color: $icon-color2;
                background: $icon-color2;
            }
            > div {
                border-color: $icon-color2;
            }
        }

        &:hover {
            >.icon {
                box-shadow: inset 0 0 5px #fff;
            }
        }
    }

    li.qc {
        >.icon {
            color: #fff;
            background: $brand-danger;
            height: auto;
            padding-bottom: 25px;

            i {
                display: block;
                padding-bottom: 60px;
            }

            .text {
                display: block;
                transform: rotate(-90deg);
                text-transform: uppercase;
                font-size: 20px;
                line-height: 1em;
                // padding-top: 1px;
                // margin-bottom: 10px;
                // text-align: right;
            }
        }

        >div {
            border-color: $brand-danger;
        }
    }

    .close {
        margin: 5px;
    }

    @include media("<=small") {
        position: static;
        margin: 0;
        padding: 10px;

        >li {
            float: none;
            width: auto;
            // max-width: 480px;

            .icon {
                display: none !important;
            }

            div {
                position: static;
                top: auto;
                left: auto;
                width: auto;
            }
        }
    }
}

// 

#qc-form {
    margin: 10px 0;

    .text-intro {
        .title {
            color: $brand-danger;
            h4 {
                font-size: 1em;
            }
        }

        .infolinia {
            h3 {
                font-size: 1.3em;
                font-weight: 600;
                margin-bottom: 0;
            }
            h4 {
                margin-top: 0;
                font-size: 0.8em;
            }
        }

        .form-info {
            h5 {
                font-size: 1.15em;
                margin-bottom: 0.2em;
            }

            p {
                font-size: 0.9em;
            }
        }
    }

    .form-area {
        text-align: right;
    }
}

// 

#qc-form {
    .container-fluid {
        padding: 0 10px;
    }

    .form-group {
        label {
            font-size: 0.9em;
        }
    }
    small {
        font-weight: bold;
    }
}

// 

.int-price {
    // clear: both;

    .int-inner {
        display: inline-block;
        padding: 6px 10px;
        border-radius: 5px;
        // background: #fff;
        font-size: 1.2em;
        color: darken(#5aaaf5, 30%);
        border: 2px solid #0072bc;
        transition: all 0.3s ease;
        
        strong {
            color: #000;
        }

        span {
            font-weight: bold;
        }

        &:hover {
            background: lighten(#5aaaf5, 30%);
            text-decoration: none;
        }
    }
}

.int-calc {
    display: none;
}

// 
// payu
// 

.font-heading {
    font-family: 'Open Sans', sans-serif;
}
p {
    font-size: 12px;
}
.payu-smaller {text-align: justify}
.payu-left {
    float: left;
}
.payu-right {
    float: right;
}
#payu-installment-calc {
    margin: 20px 15px;
    border: 1px solid #dadfdf;
    background: #fdfdfd;
    border-radius: 5px;
    padding: 15px 25px;
    max-width: 368px;
    color: #6f6f6f;
    font-family: Arial, tahoma, sans-serif;
}
.payu-val {
    font-family: 'Open Sans', sans-serif;
    color: #0072bc;
    font-size: 24px;
}
.payu-total-cost {
    background: #0072bc;
    color: #ffffff;
    border-radius: 5px;
    margin: 32px 0 10px 0;
}
.payu-total-cost p {
    margin: 0 !important;
    padding: 16px;
    overflow: hidden;
    line-height: 18px;
}
.payu-total-cost p:first-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.payu-total-cost .payu-val {
    color: #ffffff;
}
.payu-slider {
    clear: both;
    height: 8px;
    position: relative;
    border-radius: 5px;
    margin: 20px 0 10px;
    background: #f6f6f6;
    border: 1px solid #D9D6D6;
    background: linear-gradient(top, #f6f6f6, #ffffff, #fafafa);
    background: -o-linear-gradient(top, #f6f6f6, #ffffff, #fafafa);
    background: -ms-linear-gradient(top, #f6f6f6, #ffffff, #fafafa);
    background: -moz-linear-gradient(top, #f6f6f6, #ffffff, #fafafa);
    background: -webkit-linear-gradient(top, #f6f6f6, #ffffff, #fafafa);
}
.payu-slider .ui-slider-range {
    top: -1px;
    left: -1px;
    padding: 0;
    height: 8px;
    position: absolute;
    border-radius: 5px 0 0 5px;
    border: 1px solid transparent;
    background: #0072bc;
}
.payu-slider .ui-slider-handle {
    width: 22px;
    height: 22px;
    display: block;
    margin-left: -11px;
    top: -9px;
    border-radius: 12px;
    position: absolute;
    background: #f3f3f3;
    border: 1px solid #cccaca;
    box-shadow: 0 0 2px #cccaca;
    background: linear-gradient(top, #ffffff, #f3f3f3);
    background: -o-linear-gradient(top, #ffffff, #f3f3f3);
    background: -ms-linear-gradient(top, #ffffff, #f3f3f3);
    background: -moz-linear-gradient(top, #ffffff, #f3f3f3);
    background: -webkit-linear-gradient(top, #ffffff, #f3f3f3);
}
#payu-installment-calc hr {
    border: none;
    height: 1px;
    background: #dbdbdb;
    margin: 20px 0;
}
#payu-credit-interest {
    font-size: 11px;
    text-align: right;
    margin: 10px 0;
}
#credit-insurance-checkbox {
    width: 24px;
    height: 24px;
    bottom: -6px;
    position: relative;
    display: inline-block;
    background: url('https://secure.payu.com/res/v2/images/interest-checkbox.png') 0 0 no-repeat;
}
#credit-insurance-checkbox.payu-checked {
    background-position: 0 100%;
}


/* docks style*/
#payu-installment-dock {
    position: fixed;
    top: 10%;
    z-index: 4999;
    margin-top: 204px;
}
#payu-installment-dock-handle {
    float: left;
    width: 60px;
    height: 250px;
    background: url('https://secure.payu.com/res/v2/images/sprites.png') no-repeat 0 0;
}
#payu-installment-dock-handle > div {
    width: 60px;
    height: 250px;
    background: url('https://secure.payu.com/res/v2/images/sprites.png') no-repeat 0 -250px;
}
#payu-installment-dock-content {
    float: left;
    background: #028fcc url('https://secure.payu.com/res/v2/images/dock_bg.png') no-repeat center center;
    width: 250px;
    height: 250px;
    font-family: Arial, tahoma, sans-serif;
    font-size: 14px;
    color: white;
}
#payu-installment-dock-content a,
#payu-installment-dock-content a:link,
#payu-installment-dock-content a:visited {
    font-size: 11px;
    color: #233588;
    text-decoration: underline;
}
#payu-installment-dock-content a:hover {
    text-decoration: none;
}
#payu-installment-dock-content ul {
    list-style-image: url('https://secure.payu.com/res/v2/images/dock_list_tip.png');
    padding-left: 35px;
    line-height: 150%;
    text-shadow: 1px 2px #007eb4;
}
#payu-installment-dock-content li {
    margin: 7px 0;
}
#payu-installment-dock-content > .payu-installment-dock-logo > img {
    margin: 40px 0 0 20px;
    border: 0;
}
#payu-installment-dock-content > .payu-installment-dock-details {
    float: right;
    margin: 20px 20px 0 0;
}
/* themes */
.payu-theme.payu-theme-gray #payu-installment-dock #payu-installment-dock-content {
    background-image: url('https://secure.payu.com/res/v2/images/dock_bg_gray.png');
    background-color: #a1a3a4;
}
.payu-theme.payu-theme-gray #payu-installment-dock a {
    color: #3e3e3e;
}
.payu-theme.payu-theme-gray #payu-installment-dock #payu-installment-dock-handle {
    background-position: -60px 0;
}
/* en */
.payu-lang-en #payu-installment-dock #payu-installment-dock-handle > div {
    background-position: -60px -250px;
}
.payu-theme-sticker {
    background: url('https://secure.payu.com/res/v2/images/payu_sticker.png') no-repeat 0 50%;
}
.payu-lang-en.payu-theme-sticker {
    background: url('https://secure.payu.com/res/v2/images/payu_sticker_en.png') no-repeat 0 50%;
}
span.payu-currency {
    font-weight: bold;
}
strong {
    font-family: 'Open Sans', sans-serif;
}


@include media("<=small") {
    #payu-installment-dock {
        position: static;
        float: right;
        // position: absolute;
        right: 0 !important;
        top: 0;
        margin-top: 0;
        z-index: auto;
    }
}
