//
//  Variables
//  _____________________________________________

$minicart__border-color      : $color-gray80;
$minicart__padding-horizontal: $indent__base;

$minicart-qty__height        : 24px;

//
//  Minicart
//  ---------------------------------------------

.block-minicart {
    .items-total {
        float: left;
        margin: 0 $indent__s;

        .count {
            font-weight: $font-weight__bold;
        }
    }

    .subtotal {
        margin: 0 $indent__s;
        text-align: right;

        .label {
            @extend .abs-colon;
        }
    }

    .amount {
        .price-wrapper {
            &:first-child {
                .price {
                    font-size: $font-size__l;
                    font-weight: $font-weight__bold;
                }
            }
        }
    }

    .subtitle {
        display: none;

        &.empty {
            display: block;
            font-size: 14px;
            padding: $indent__l 0 $indent__base;
            text-align: center;
        }
    }

    .text {
        &.empty {
            text-align: center;
        }
    }

    .block-content {
        > .actions {
            margin-top: 15px;
            text-align: center;

            > .primary {
                margin: 0 $indent__s 15px;

                .action {
                    &.primary {
                        @extend .abs-button-l;
                        display: block;
                        margin-bottom: 15px;
                        width: 100%;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .block-category-link,
    .block-product-link,
    .block-cms-link,
    .block-banners {
        margin: 15px 0 0;
        text-align: center;
    }
}

.minicart-wrapper {
    @include lib-dropdown(
        $_toggle-selector                    : '.action.showcart',
        $_options-selector                   : '.block-minicart',
        $_dropdown-toggle-icon-content       : $icon-cart,
        $_dropdown-toggle-active-icon-content: $icon-cart,
        $_dropdown-list-item-padding         : false,
        $_dropdown-list-item-hover           : false,
        $_icon-font-position                 : before,
        $_icon-font-size                     : 35px,
        $_icon-font-line-height              : 33px,
        $_icon-font-color                    : $minicart-icons-color,
        $_icon-font-color-hover              : $minicart-icons-color-hover,
        $_icon-font-color-active             : $minicart-icons-color
    );
    float: right;

    .block-minicart {
        padding: 25px $minicart__padding-horizontal;
        right: 0;
        width: 320px;

        .block-title {
            display: none;
        }

        &:after {
            left: auto;
            right: 25px;
        }

        &:before {
            left: auto;
            right: 26px;
        }
    }

    .product {
        .actions {
            float: right;
            margin: -24px 0 0;
            text-align: right;

            > .primary,
            > .secondary {
                display: inline;
            }
        }
    }

    .action {
        &.close {
            @include lib-button-icon(
                $icon-remove,
                $_icon-font-size       : 32px,
                $_icon-font-line-height: 32px,
                $_icon-font-text-hide  : true
            );
            @include lib-button-reset();
            height: 40px;
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
        }

        &.showcart {
            white-space: nowrap;

            .text {
                @extend .abs-visually-hidden;
            }

            .counter.qty {
                background: $active__color;
                color: $page__background-color;
                height: $minicart-qty__height;
                line-height: $minicart-qty__height;
                border-radius: 2px;
                display: inline-block;
                margin: 3px 0 0;
                min-width: 18px;
                overflow: hidden;
                padding: 0 3px;
                text-align: center;
                white-space: normal;

                &.empty {
                    display: none;
                }

                .loader {
                    > img {
                        max-width: $minicart-qty__height;
                    }
                }
            }

            .counter-label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .minicart-widgets {
        margin-top: 15px;
    }
}

.minicart-items-wrapper {
    border: 1px solid $minicart__border-color;
    margin: 0 (-$minicart__padding-horizontal);
    border-left: 0;
    border-right: 0;
    overflow-x: auto;
    padding: 15px;
}

.minicart-items {
    @include lib-list-reset-styles();

    .product-item {
        padding: $indent__base 0;

        &:not(:first-child) {
            border-top: 1px solid $minicart__border-color;
        }

        &:first-child {
            padding-top: 0;
        }

        > .product {
            @extend .abs-add-clearfix;
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .product-item-pricing {
        .label {
            display: inline-block;
            width: 4.5rem;
        }
    }

    .price-minicart {
        margin-bottom: $indent__xs;
    }

    .product {
        > .product-item-photo,
        > .product-image-container {
            float: left;
        }

        .toggle {
            @include lib-icon-font(
                $_icon-font-content    : $icon-down,
                $_icon-font-size       : 28px,
                $_icon-font-line-height: 16px,
                $_icon-font-text-hide  : false,
                $_icon-font-position   : after,
                $_icon-font-display    : block
            );
            cursor: pointer;
            position: relative;

            &:after {
                position: static;
            }
        }

        &.active {
            > .toggle {
                @include lib-icon-font-symbol(
                    $_icon-font-content : $icon-up,
                    $_icon-font-position: after
                );
            }
        }
    }

    .product-item-name {
        font-weight: $font-weight__regular;
        margin: 0 0 $indent__s;

        a {
            color: $link__color;
        }
    }

    .product-item-details {
        padding-left: 88px;

        .price {
            font-weight: $font-weight__bold;
        }

        .price-including-tax,
        .price-excluding-tax {
            margin: $indent__xs 0 0;
        }

        .weee[data-label] {
            font-size: 11px;
            .label {
                @extend .abs-no-display;
            }
        }

        .details-qty {
            margin-top: $indent__s;
        }
    }

    .product.options {
        .tooltip.toggle {
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size       : 28px,
                $_icon-font-line-height: 28px,
                $_icon-font-text-hide  : true,
                $_icon-font-margin     : -3px 0 0 7px,
                $_icon-font-position   : after
            );

            .details {
                display: none;
            }
        }
    }

    .details-qty,
    .price-minicart {
        .label {
            @extend .abs-colon;
        }
    }

    .item-qty {
        margin-right: $indent__s;
        text-align: center;
        width: 40px;
    }

    .update-cart-item {
        font-size: 11px;
        vertical-align: top;
    }

    .subtitle {
        display: none;
    }

    .action {
        &.edit,
        &.delete {
            @include lib-icon-font(
                $icon-settings,
                $_icon-font-size        : 28px,
                $_icon-font-line-height : 28px,
                $_icon-font-text-hide   : true,
                $_icon-font-color       : $color-gray19,
                $_icon-font-color-hover : $color-gray19,
                $_icon-font-color-active: $color-gray19
            );
        }

        &.delete {
            @include lib-icon-font-symbol($_icon-font-content: $icon-trash);
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__xs) {
    .minicart-wrapper .block-minicart {
        width: 290px;
    }
}

@include max-screen($screen__s) {
    .minicart-wrapper {
        margin-top: $indent__s;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .minicart-wrapper {
        margin-left: 13px;
        .block-minicart {
            right: -15px;
            width: 390px;
        }
    }
}
