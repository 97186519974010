// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Common
//  _____________________________________________
.panel.header {
    .links,
    .switcher {
        display: none;
    }
}

.nav-sections {
    background: $navigation__background;
}

.nav-toggle {
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size       : 28px,
        $_icon-font-color      : $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    left: 15px;
    position: absolute;
    top: 15px;
    z-index: 14;
}

//
//  Mobile
//  _____________________________________________

$active-nav-indent: 54px;
@include max-screen($screen__m - 1) {
    .navigation {
        padding: 0;

        .parent {
            .level-top {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content : $icon-down,
                    $_icon-font-size    : 42px,
                    $_icon-font-position: after,
                    $_icon-font-display : block
                );

                &:after {
                    position: absolute;
                    right: 7px;
                    top: -8px;
                }

                &.ui-state-active {
                    @include lib-icon-font-symbol(
                        $_icon-font-content : $icon-up,
                        $_icon-font-position: after
                    );
                }
            }
        }
    }

    .nav-sections {
        transition: left 0.3s;
        height: 100%;
        left: -80%;
        left: calc(-1 * (100% - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        width: 80%;
        width: calc(100% - #{$active-nav-indent});

        .switcher {
            border-top: 1px solid $color-gray82;
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
            padding: 0.8rem 3.5rem 0.8rem 2rem;

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray82;
            }

            &-trigger {
                strong {
                    display: inline-block;
                    position: relative;
                    @include lib-icon-font(
                        $_icon-font-content : $icon-down,
                        $_icon-font-size    : 42px,
                        $_icon-font-position: after,
                        $_icon-font-display : block
                    );

                    &:after {
                        position: absolute;
                        right: -40px;
                        top: -18px;
                    }
                }

                &.active strong {
                    @include lib-icon-font-symbol(
                        $_icon-font-content: $icon-up,
                        $_icon-font-position: after
                    );
                }
            }

            &-dropdown {
                @include lib-list-reset-styles();
                padding: $indent__s 0;
            }
        }

        .header.links {
            @include lib-list-reset-styles();
            border-bottom: 1px solid $color-gray82;

            li {
                font-size: $font-size__base;
                margin: 0;

                &.greet.welcome {
                    border-top: 1px solid $color-gray82;
                    font-weight: 700;
                    padding: 0.8rem $indent__base;
                }

                & > a {
                    border-top: 1px solid $color-gray82;
                }
            }

            a,
            a:hover {
                color: $navigation-level0-item__color;
                text-decoration: $navigation-level0-item__text-decoration;
                display: block;
                font-weight: 700;
                padding: 0.8rem $indent__base;
            }

            .header.links {
                border: 0;
            }
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            transition: left 0.3s;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: 80%;
            left: calc(100% - #{$active-nav-indent});
        }

        .nav-sections {
            $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

            box-shadow: $_shadow;
            left: 0;
            z-index: 99;
        }

        .nav-toggle {
            &:after {
                background: rgba(0, 0, 0, 0.01);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

    .nav-sections {
        &-items {
            @include lib-clearfix();
            position: relative;
            z-index: 1;
        }

        &-item-title {
            background: darken($navigation__background, 5%);
            border: solid darken($navigation__background, 10%);
            border-width: 0 0 1px 1px;
            box-sizing: border-box;
            float: left;
            height: 71px;
            padding-top: 24px;
            text-align: center;
            width: 33.33%;

            &.active {
                background: transparent;
                border-bottom: 0;
            }

            .nav-sections-item-switch {
                &:hover {
                    text-decoration: none;
                }
            }
        }

        &-item-content {
            @include lib-clearfix();
            box-sizing: border-box;
            float: right;
            margin-left: -100%;
            margin-top: 71px;
            width: 100%;

            &.active {
                display: block;
            }

            padding: $indent__m 0;
        }
    }

    @include lib-main-navigation();
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .nav-toggle {
        display: none;
    }

    .nav-sections {
        flex-grow: 0;
        flex-basis: auto;
        margin-bottom: $indent__m;

        &-item-title {
            display: none;
        }

        &-item-content {
            display: block;
        }

        &-item-content > * {
            display: none;
        }

        &-item-content > .navigation {
            display: block;
        }
    }

    // @include lib-main-navigation-desktop();

    .panel.header {
        .links,
        .switcher {
            display: inline-block;
        }
    }

    .nav-sections {
        background: #202063;
    }

    .navigation {
        position: relative;

        ul {
            margin: 0;

            li {
            }
        }

        li.level0 {
            display: inline-block;
            margin: 0;
            padding: 0;

            >a {
                position: relative;
                display: block;
                padding: 15px 9px;
                font-size: 18px;
                font-weight: 700;
                color: #fff;
                text-transform: uppercase;
                white-space: nowrap;

                &:before {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    height: 2px;
                    background: transparent;
                    content: '';
                    transition: all 0.3s ease;
                }

                &:hover {
                    text-decoration: none;

                    &:before {
                        background: #fff;
                    }
                }

                small {
                    font-size: 0.7em;
                    text-transform: none;
                }
            }

            &.home {
                >a {
                    background: url('../images/home.png') no-repeat center center;
                    width: 19px;
                    // height: 25px;

                    >span {
                        // display: none;
                        display: block;
                        text-indent: -9999px;
                    }
                }
            }

            >.submenu {
                position: absolute;
                display: block;
                visibility: hidden;
                top: 55px;
                left: 20px;
                right: 20px;
                background: #fff;
                z-index: 5000;
                box-shadow: 0 2px 12px rgba(#000, 0.6);
                transition: all 0.3s ease;
                opacity: 0;
                transform: translateY(-10px);
            }

            &:hover {
                >.submenu {
                    visibility: visible;
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        li.level1 {
            display: block;
            float: left;
            width: 20%;
            margin: 10px 0;
            padding: 10px 0;
            padding-left: 1%;
            border-right: 3px solid #e0e0e0;
            box-sizing: border-box;

            >a {
                color: #002460;
                font-size: 16px;
                font-weight: 600;
            }
        }

        li.level2 {
            >a {
                color: #000;
                font-size: 15px;
            }
        }
    }
}
