// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

// @include lib-font-face(
//     $family-name: $font-family-name__base,
//     $font-path: '../fonts/opensans/light/opensans-300',
//     $font-weight: 300,
//     $font-style: normal
// );

// @include lib-font-face(
//     $family-name: $font-family-name__base,
//     $font-path: '../fonts/opensans/regular/opensans-400',
//     $font-weight: 400,
//     $font-style: normal
// );

// @include lib-font-face(
//     $family-name: $font-family-name__base,
//     $font-path: '../fonts/opensans/semibold/opensans-600',
//     $font-weight: 600,
//     $font-style: normal
// );

// @include lib-font-face(
//     $family-name: $font-family-name__base,
//     $font-path: '../fonts/opensans/bold/opensans-700',
//     $font-weight: 700,
//     $font-style: normal
// );

//
//    Desktop
//--------------------------------------
@include min-screen($screen__m) {
    h1 {
        font-size: $h1__font-size-desktop;
        margin-bottom: $h1__margin-bottom__desktop;
    }
}


//
//    Common
//--------------------------------------

.items {
    @include lib-list-reset-styles();
}
